<template>
    <div class="order_container">
       <div class="noOrder" v-if="orderList.length===0">暂无订单信息~~</div>
       <el-card v-else class="box-card" v-for="item in orderList" :key="item.id">
            <div slot="header" class="clearfix">
                <span>{{item.productName}}</span>
                <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
            </div>
            <div>姓名：{{item.patientName}}</div>
            <div>电话：{{item.patientPhone}}</div>
            <div>社区名称：{{item.orgName}}</div>
            <div>医生姓名：{{item.doctorName}}</div>
            <div>数量：{{item.num}}</div>
            <div>状态：{{item.status===0?'已取消':(item.status===1?'已支付':'已退款')}}</div>
        </el-card>
    </div>
</template>

<script>
import {getOrderInfo, wxauth, getOpenId} from '../api/index'
  export default {
    data(){
        return{
            orderList: [],
            openId: '',
            wxCode: '',
            wxUrl: ''
        }
    },
    created(){
        this.wxUrl = window.location.href
        // 获取地址兰参数
        let { code } = this.getUrlKey()
        this.wxCode = code
        // 取消支付时回显
        // this.
        if (!this.wxCode) {
          // 微信授权登录
          this.auth(this.wxUrl)
        } else {
          // 获取openId
          this.getwxOpenId(this.wxCode)
        }
    },
    mounted(){
        document.title = '订单列表'
    },
    methods: {
      async getOrderInfoList(openId){
        let params = {
            page: 1,
            pageSize: 10,
            openId: openId
        }
        let  result = await getOrderInfo(params)
            if (result.code === 1001) {
              this.orderList = result.data.list
            }

        },
      // 获取地址栏参数
     getUrlKey() {
      var url = window.location.href; //获取当前url
      var urls = url.split('#')[0]
      var cs = urls.split('?')[1]
      if (cs) {
        var cs_arr = cs.split("&"); //参数字符串分割为数组
        var cs1 = {};
        for (var i = 0; i < cs_arr.length; i++) {
          //遍历数组，拿到json对象
          cs1[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
        }
        return {
          code: cs1.code,
          doctorName: decodeURIComponent(cs1.doctorName) // 地址栏中文参数解析
        }
      } else {
        return {
          code: '',
          doctorName: ''
        }
      }
      
    },
        // 微信授权
   async auth(value) {
      let result = await wxauth({callback: value})
      if (result) {
          window.location.href = result
      }
    },
    async getwxOpenId(code){
     let result = await getOpenId({code: code})
     if (result.code===1001) {
        this.openId = result.data
        this.getOrderInfoList(this.openId)
     }
    },
  },
}
</script>

<style lang="less" scoped>
.order_container {
    padding: 5px 10px;
    box-sizing: border-box;
}
.text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 100%;
    margin-bottom: 10px;
  }
  /deep/.el-card__header{
    padding: 5px 10px
  }
  .noOrder{
    text-align: center;

  }
</style>