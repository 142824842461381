import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Order from '../views/Order.vue'
import Hospital from '../views/Hospital.vue'
import Registered from "../views/Registered.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'shopInfo'
  },
  {
    path: '/shopInfo',
    name: 'Home',
    component: Home
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  },
  {
    path: '/hospital',
    name: 'Hospital',
    component: Hospital
  },
  {
    path: '/registered',
    name: 'Registered',
    component: Registered
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
