<template>
  <div class="container">
    <div>申请成功后可进入医护人员入口监测患者数据</div>
    <div class="selectInfo">
      <div class="info">
        <span class="form_label">手机号：</span>
        <el-input
          v-model="phone"
          type="number"
          class="inp_length client_phone"
          size="mini"
          placeholder="请输入手机号"
        ></el-input>
      </div>
      <div class="info">
        <span class="form_label">请选择社区医院：</span>
        <el-select
          v-model="district"
          class="inp_length course_address"
          size="mini"
          @change="handleChange"
          placeholder="请选择区县"
        >
          <el-option
            v-for="item in districtList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
        <el-select
          v-model="hospital"
          class="inp_length course_address"
          size="mini"
          placeholder="请选择社区医院"
        >
          <el-option
            v-for="item in hospitalList"
            :key="item.id"
            :value="item.store_id"
            :label="item.store_name"
          ></el-option>
        </el-select>
      </div>
      <div>
        <el-button
          style="width: 100%"
          type="primary"
          size="small"
          @click="submit"
          >提交</el-button
        >
      </div>
    </div>
    <div class="img_box">
      <span>小程序二维码：</span>
      <img src="../assets/minigrop.jpg" />
    </div>
    <div>
      <p>提示：</p>
      <p>1.新用户请先进入小程序进行登录</p>
      <p>2.长按图片识别二维码进入小程序</p>
    </div>
  </div>
</template>

<script>
import { submit, getHospitalList } from "../api/index";
import axios from "axios";
export default {
  data() {
    return {
      phone: "",
      name: "",
      hospital: "",
      district: "",
      districtList: [],
      hospitalList: [],
    };
  },
  methods: {
    getHospitalList() {
      getHospitalList().then((res) => {
        if (res.status === 200) {
          this.districtList = res.data;
        }
      });
    },
    handleChange(e) {
      let that = this;
      that.hospital = "";
      that.hospitalList = [];
      that.districtList.forEach((item) => {
        if (item.id === e) {
          // if (e === 10321) {
          //   that.hospitalList.push({
          //     store_name: item.store_name,
          //     store_id: item.store_id,
          //   });
          // } else {
            that.hospitalList = item.children;
          // }
        }
      });
    },
    submit() {
      let params = {
        phone: this.phone,
        store_id: this.hospital,
      };
      submit(params).then((res) => {
        if (res.status === 200) {
          this.$message.success(
            `您已成功开通${res.data.name}医护人员权限，马上进入小程序医护人员入口查看患者数据`
          );
        }
        if (res.status === 400) {
            this.$message.error(res.msg);
        }
      });
    },
    getaccessToken() {
      axios
        .get("https://api.weixin.qq.com/cgi-bin/token", {
          params: {
            grant_type: "client_credential",
            appid: "wxba52ccdd2c907199",
            secret: "2b23678f14d60f17bfc7670740b995ab",
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    // this.getaccessToken()
    this.getHospitalList();
    document.title = "医护人员权限申请入口";
  },
};
</script>

<style lang="less" scoped>
.el-select-dropdown__wrap {
  margin: 0 !important;
}
.img_box {
  display: flex;
  img {
    width: 200px;
    height: 200px;
  }
}
.container {
  padding: 15px;
}
.selectInfo {
  background-color: #ffffff;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
}
.info {
  margin: 10px 0;
  display: flex;
}
.form_label {
  display: inline-block;
  width: 130px;
  text-align: right;
}
.inp_length {
  flex: 1;
}
.bottom_btnbox {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  width: 100%;
  height: 50px;
  background-color: #9fd6a0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>