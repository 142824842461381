<template>
    <div class="warp">
       <!-- <div class="header">商品详情</div> -->
       <div class="banner">
       </div>
       <div class="content">
        <p class="money">
          <span>￥</span>
          <span class="price">632</span>
        </p>
        <p class="courseName">贵州中医药大学第二附属医院心脏康复远程运动康复课程（10节课）</p>
       </div>
       <div class="selectInfo">
          <div class="info">
            <span class="form_label">上课地点：</span>
            <el-select v-model="course_address" class="inp_length course_address" size="mini">
                <el-option v-for="item in courseData" :key="item.org_id" :value="item.name" :label="item.name"></el-option>
            </el-select>
          </div>
          <div class="info">
            <span class="form_label">姓名：</span>
            <el-input v-model="name" class="inp_length client_name" size="mini" placeholder="请输入姓名"></el-input>
          </div>
          <div class="info">
            <span class="form_label">手机号：</span>
            <el-input v-model="phone" type="number" class="inp_length client_phone" size="mini" placeholder="请输入手机号"></el-input>
            <!-- <el-input-number class="inp_length client_phone"  placeholder="请输入手机号" :controls="false" size="mini" v-model="phone"></el-input-number> -->
          </div>
          <div class="info">
            <span class="form_label">数量：</span>
            <el-input-number size="mini" class="inp_length num" v-model="num" @change="handleChange" :min="1" :max="20"></el-input-number>
            <!-- <el-input v-model="num" class="inp_length num" size="mini"  placeholder="请输入数量"></el-input> -->
          </div>
          <div>
            <span>医生姓名：{{doctorName}}</span>
          </div>
       </div>
        <div class="shop_content">
          <img src="../assets/shopcontent.jpg">
          <img class="fximg" src="../assets/fenxiang.jpg" alt="">
        </div>
       <div class="bottom_btnbox">
        <el-button class="btn" @click="placeOrder">立即购买</el-button>
       </div>
    </div>
</template>

<script>
import { getPayInfo,wxauth, getOpenId, getAccessToken } from '../api/index'
import courseList from '../utils/courseList.json'
import { wxChatShare } from '../utils/wxConfig'
export default {
  name: 'Home',
  components: {
  },
  data(){
    return {
      wechatPayId: '',
      wxUrl: '',
      wxCode: '',
      doctorName: '',
      openId: '',
      course_address: '',
      name: '',
      phone: null,
      num: 1,
      courseData: [],
    }
  },
  created() {
    // 机构数据
    this.courseData = courseList
    // 获取地址
    this.wxUrl = window.location.href
    // 获取地址兰参数
    let { code, doctorName} = this.getUrlKey()
    this.wxCode = code
    this.doctorName = doctorName
    // 取消支付时回显
    // this.
    if (!this.wxCode) {
      localStorage.setItem('initialize',this.wxUrl)
      // 微信授权登录
      this.auth(this.wxUrl)
    } else {
      // 获取openId
      this.getwxOpenId(this.wxCode)
    }
    this.getSignature()
  },
  mounted(){
  },
  methods:{
        //请求后台的方法
    async getSignature(){
        let {code, data} = await getAccessToken({url: location.href})
        if (code === 1001) {
          let params = JSON.parse(JSON.stringify(data))
          params['title'] = '快来和我一起加入三甲医院心康小屋计划'
          params['imgUrl'] = 'https://m.1ekj.com/activity/img/fenxiang.420d916e.jpg'
          params['desc'] = '安全、便捷、易掌握的专业心脏康复训练'
         wxChatShare(params)
        }
     },
    // 获取地址栏参数
     getUrlKey() {
      var url = window.location.href; //获取当前url
      var urls = url.split('#')[0]
      // if (urls) {
      //   var code_url = urls[0].split("?")[1]; //获取?之后的参数字符串
      //   if(code_url){
      //     var code_arr = code_url.split("&")
      //     var code_data = {}
      //     for (var i = 0; i < code_arr.length; i++) {
      //         //遍历数组，拿到json对象
      //         code_data[code_arr[i].split("=")[0]] = code_arr[i].split("=")[1];
      //     }
      //   }
      //   var name_url = urls[1].split('?')[1]
      //   var doctorName_code = name_url?name_url.split('=')[1]:''
      //   var doctorName = decodeURIComponent(doctorName_code)
      //   return{
      //     doctorName,
      //     code: code_arr?code_data.code:''
      //   }
      // }
      var cs = urls.split('?')[1]
      if (cs) {
        var cs_arr = cs.split("&"); //参数字符串分割为数组
        var cs1 = {};
        for (var i = 0; i < cs_arr.length; i++) {
          //遍历数组，拿到json对象
          cs1[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
        }
        return {
          code: cs1.code,
          doctorName: decodeURIComponent(cs1.doctorName) // 地址栏中文参数解析
        }
      } else {
        return {
          code: '',
          doctorName: ''
        }
      }
      
    },
    async getwxOpenId(code){
     let result = await getOpenId({code: code})
     if (result.code===1001) {
        this.openId = result.data
        localStorage.setItem('wxOpenId',this.openId)
     }
    },
    handleChange(e){
      this.num = e
    },
    // 下单获取弹起微信支付窗口
    async getInfo() {
      let data = {
        name: this.name,
        course_address: this.course_address,
        phone: this.phone,
        num: this.num
      }
      localStorage.setItem('orderInfo',JSON.stringify(data))
      let params = {
            productName: '贵州中医药大学第二附属医院心脏康复远程运动康复课程（10节课）',
            price: 0.01*100,
            openId: this.openId,
            doctorName: this.doctorName,
            patientName: this.name,
            orgName: this.course_address,
            patientPhone: this.phone,
            num: this.num,
          }
      let result = await getPayInfo(params)
          if (result.code===1001) {
            this.onBridgeReady(result.data)
          }
    },
    // 微信授权
   async auth(value) {
      let result = await wxauth({callback: value})
      if (result) {
          window.location.href = result
      }
    },
    // 点击立即购买
    placeOrder(){
      if (this.name&&this.num&&this.phone&&this.course_address) {
         this.getInfo()
      } else {
        this.$message.info('请输入完整信息')
      }
      
    },

    //  唤醒支付
    onBridgeReady(val){	
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
                  'getBrandWCPayRequest', {
                     "appId":val.appId,     //公众号ID，由商户传入     
                     "timeStamp":val.timestamp,         //时间戳，自1970年以来的秒数     
                     "nonceStr": val.nonceStr, //随机串     
                     "package":  `prepay_id=${val.prepayId}`, // JSAPI下单接口返回的prepay_id参数值  
                     "signType":val.signType,         //微信签名方式：     
                     "paySign":val.sign //微信签名 
                  },
                  function(res){
                  if(res.err_msg == "get_brand_wcpay_request:ok" ){
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                        //跳转支付成功展示页面
                      // window.location.href =  "https://m.1ekj.com/activity/#/order";
                  }else if(res.err_msg == "get_brand_wcpay_request:fail" ){
                      console.log("支付失败")
                  }else{
                    window.location.href = localStorage.getItem('initialize')
                  }
               });
    },
    // 取消支付
  //  cancelPayMoney(){
  //       $.ajax({
  //           type:"post",
  //           url:path+"/wechatPay/cancelPay",
  //           data:{
  //               "id":wechatPayId,
  //           },
  //           dataType: "json", 
  //           async:false,
  //           success:function(result){
                
  //           },
  //           error:function(){
  //               console.log("取消订单失败"+"");
  //           }
  //       })
  //   }
  }
}
</script>
<style lang="less" scoped>
html,body{
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}
.fximg{
  display: none;
}
p{
    padding: 0;
    margin: 0;
}
.warp{
  position: relative;
    height: 100%;
    width: 100%;
    background-color: #f2f5f7;
}
.header{
    width: 100%;
    height: 7%;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}
.banner{
    width: 100%;
    height: 25%;
    background-color: aquamarine;
    background-image: url("../assets/banner.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
}
.shop_content{
  // height: 4466px;
  width: 100%;
  padding-bottom: 50px;
  // background-image: url('../assets/shopcontent.jpg');
  // background-repeat: no-repeat;
  // background-size: 100%;
  img{
    width: 100%;
    // height: 100%;
  }
}

.content{
    background-color: #ffffff;
    margin: 10px;
    padding: 5px;
    border-radius: 10px;

}
.money{
    color: red;
    font-size: 18px;
    margin: 5px;
}
.courseName{
    margin: 5px;
    line-height: 20px;
    line-height: 25px;
}
.selectInfo{
    background-color: #ffffff;
    margin: 10px;
    padding: 5px;
    border-radius: 10px;
}
.info{
    margin: 10px 0;
    display: flex;
}
.form_label{
    display: inline-block;
    width: 80px;
    text-align: right
}
.bottom_btnbox{
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    width: 100%;
    height: 50px;
    background-color: #9fd6a0;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.btn{
    color: #ffffff !important;
    font-size: 20px;
    width: 200px;
    // border-radius: 15px;
    /* background-color: #ff5722; */
    background-color: transparent !important;
    border: none;
    // position: fixed;
    
}
.inp_length{
    width: 170px;
    height: 25px;
    padding: 0;
    margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"]{
    -moz-appearance: textfield;
}
</style>
