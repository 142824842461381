//  wetchat.js  －－ 个人封装
import wx from 'weixin-js-sdk'; // 这是自己优化的sdk方法，可以解决ios签名报错的一些问题
// import wx from 'weixin-js-sdk'; // 这是官方的微信sdk引入方法
export function wxChatShare(param) {  //这里的param参数在需要的页面写好，然后代入到这里来就可以了
    wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: param.appId, // 必填，公众号的唯一标识
        timestamp: param.timestamp, // 必填，生成签名的时间戳
        nonceStr: param.noncestr, // 必填，生成签名的随机串
        signature: param.signature, // 必填，签名
        jsApiList: [ // 用的方法都要加进来
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
        ]
      });
      wx.ready(function () {
        //分享到朋友圈
        wx.updateTimelineShareData({
          title: param.title, // 分享标题
          desc: param.desc, // 分享描述
        //   link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: param.imgUrl, // 分享图标
          success: function () {
            // 设置成功
            //   this.$Message.message("设置成功!");
          }
        })

        wx.onMenuShareTimeline({
          title: param.title, // 分享标题
          desc: param.desc, // 分享描述
        //   link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: param.imgUrl, // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          },
        })

        //分享给朋友

        wx.updateAppMessageShareData({
          title: param.title, // 分享标题
          desc: param.desc, // 分享描述
        //   link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: param.imgUrl, // 分享图标
          success: function () {
            // 设置成功
            //   this.$Message.message("设置成功!");
          }
        })
        wx.onMenuShareAppMessage({
          title: param.title, // 分享标题
          desc: param.desc, // 分享描述
          // link: param.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: param.imgUrl, // 分享图标
          // type: param.type, // 分享类型,music、video或link，不填默认为link
          // dataUrl: param.dataUrl, // 如果type是music或video，则要提供数据链接，默认为空
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
      });
}