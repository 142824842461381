import { post, get, qspost,getUrl,postUrl } from '../utils/request'

export const getPayInfo = (params) =>{
    return post('/jsapi/pay', params)
}
export const wxauth = (params) => {
    return get('/jsapi/wxLogin',params)
}
export const getOpenId = (params) => {
    return get('/jsapi/getOpenId', params)
}
export const getOrderInfo = (params)=>{
    return qspost('/order/userOrder', params)
}
export const getAccessToken = (params) => {
    return get('/jsapi/getShareSign',params)
}
export const getHospitalList = (params) => {
    return getUrl('/api/pc/store_tree_list',params)
}
export const submit = (params) =>{
    return postUrl('/api/pc/set_store_doctor ', params)
}
export default {
    getPayInfo,
    wxauth,
    getOpenId,
    getOrderInfo,
    getAccessToken,
    getHospitalList,
    submit
}